import React from 'react';
import cx from 'classnames';
import { Margins } from 'components/shared/types';

import styles from './BaseButton.module.css';
import { getMarginFromProps } from 'components/shared/utils';

import Spinner from 'components/Spinner/Spinner';

type ButtonTypes =
  | 'primary-gold'
  | 'primary-black'
  | 'secondary-dark-grey'
  | 'secondary-ghost'
  | 'secondary-white'
  | 'secondary-gold';

type ButtonSizes = 'small' | 'medium' | 'large' | 'full';
type ButtonProps = {
  variant?: ButtonTypes;
  size?: ButtonSizes;
  isLoading?: boolean;
} & Margins &
  JSX.IntrinsicElements['button'];

const Button: React.FC<ButtonProps> = ({
  children,
  variant = 'primary-gold',
  size = 'large',
  isLoading,
  className,
  onClick,
  ...rest
}) => {
  const cn = cx(styles.default, styles[variant], styles[size], className);
  const handleClick = isLoading ? undefined : onClick;

  const marginStyle = getMarginFromProps(rest);
  return (
    <button
      className={cn}
      style={{
        ...marginStyle,
      }}
      onClick={handleClick}
      disabled={isLoading}
      {...rest}
    >
      {isLoading ? <Spinner /> : children}
    </button>
  );
};

export default Button;
